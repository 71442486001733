import { GlobalId, SegmentId } from '@types';

// AccountsAccess Values are returned by profile api as strings.
export enum AccountsAccess {
  ACCESS = 'access',
  NO_ACCESS = 'no-access',
  UPGRADABLE = 'upgradable',
  USER_BLOCKED = 'user-blocked',
  SYSTEM_BLOCKED = 'system-blocked',
  OFFLINE = 'offline',
}

/**
 * Profile interfaces
 */
export interface IUserProfileInfo {
  accountsAccess?: string;
  addressLine1?: string;
  businessKey?: string;
  challengeQuestions?: boolean;
  city?: string;
  dashboardUrl?: string;
  dealerNo?: string;
  displayName?: string;
  email?: string;
  firm?: string;
  hypotool?: string;
  loginName?: string;
  newApplnStatus?: string;
  phoneNumber?: string;
  rememberMe?: boolean;
  remindUpgrade?: boolean;
  role?: string; // should this be an enum?
  ssotool?: string;
  state?: string;
  userId?: string;
  userSysNo?: string;
  webExperience?: string;
  zip?: string;
  userGroup?: string;
  ostId?: string;
  actor?: string;
  pcsAcceptFlag?: string;
  rivaPartyId?: string;
  expiryTimeInSeconds?: string;
  pingToken?: string; // if going through auth0 to old profile app
  // myfunds profile attrs
  uid?: string;
  firstName?: string;
  lastName?: string;
  additionalRoles?: AdditionalRoles[];
  contactGlobalId?: string;
}

export enum LoginSource {
  BYPASS = 'BYPASS',
  SEGMENT_SWITCH = 'SWITCH',
  DEBUG = 'DEBUG',
  OAUTH = 'OAUTH',
  NEW = 'NEW',
  STORAGE = 'STORAGE', // the least reliable
}
export enum IsLoginUser {
  LOGGEDIN = 'logged in',
  LOGGEDOUT = 'logged out',
}

export interface IUserProfile {
  name: string;
  profileInfo: IUserProfileInfo;
  isLoggedIn: boolean;
  isIdentified?: boolean;
  loginSource?: LoginSource;
  bypassRoles?: (BypassRole | UserRole)[];
  firmRoles?: FirmRole[];
  defaultForSegment?: SegmentId;
  firm?: IFirmProfile;
}

export interface IFirmProfile {
  firmRole?: FirmRole; // also used for bypass and firm_token
  firmId?: GlobalId;
  parentFirmId?: GlobalId;
  hasProductPageLinks?: boolean;
  includeInvestedFunds?: boolean; // should invested funds automatically be added to active list?
  hideAccountAccessMenu?: boolean;
  hideSearch?: boolean;
  hideSignIn?: boolean;
  hideRoleSelectionLinks?: boolean;
}

export interface ISegmentCharacteristics {
  isLoggedIn: 'true' | 'false';
  dashboardAccess: 'true' | 'false';
  // isIdentified: string;
  segment: SegmentId;
  subsegment: string;
  firm?: string;
  loginSource?: string;
  firmId?: GlobalId;
}

// this represents profile data that is stored in LocalStorage
// WARNING! Do not store Personally Identifiable Information (PII) here
export interface ProfileSummary {
  isLoggedIn?: boolean; // stored in SessionStorage
  isIdentified?: boolean;
  role: string;
  webExperience: string;
  firm: string; // TODO: should type change to FirmRole?
  firmId?: GlobalId;
  source: LoginSource;
  accountsAccess: string;
  dashboardUrl: string;
  pcsAcceptFlag?: string;
  additionalRoles?: AdditionalRoles[];
}

export enum BypassRole {
  DC = 'internal-dc',
  FA = 'internal-advisor',
  FP = 'internal-professionals',
  INV = 'internal-investor',
  RIA = 'internal-ria',
  SH = 'internal-shareholder',
  GK = 'gatekeeper',
}

export enum FirmRole {
  EJ = 'partner-edward-jones',
  RJ = 'partner-raymond-james',
  ML = 'partner-merrill-lynch',
  PM = 'partner-primerica',
  LPL = 'partner-LPL',
  MS = 'partner-morgan-stanley',
  PBD = 'partner-brewin-dolphin',
  UNI = 'partner-unicredit',
  ATTRAX = 'partner-attrax',
}

export interface FirmIds {
  fid: GlobalId;
  pfid: GlobalId;
}

export enum UserRole {
  // Order is important to keep backward functionality
  INV = 'investor',
  SH = 'sh',
  FP = 'fp',
  DC = 'dc',
  GK = 'gk',
  INT = 'internal',
  FA = 'financial-professionals',
  INST = 'institutional',
  DISTRIBUTOR = 'distributor',
  RIA = 'ria',
}

// TODO need to add more additonal roles
export enum AdditionalRoles {
  DOC_LIBRARY = 'Doc Library',
}

export interface ProfileParams {
  role: string;
  profileId: string;
}

export interface QueryParameters {
  key: string;
  value: string;
}

export interface DashboardConfig {
  firm: string;
  dashBoardUrl: string;
}

export enum LoggedInStatus {
  NotLoggedIn = 'NotLoggedIn',
  LoggedInNoAccess = 'LoggedInNoAccess',
  LoggedInWithAccess = 'LoggedInWithAccess',
}

export enum AccessRegisterStatus {
  NOT_REGISTERED, // Set if user used token but and need activation.
  ERROR, // Set to handle data inconsistency.
  PARTIAL_REG = 'PARTIAL_REG', // Once user registered on FT.com.
  // Initial user status is PARTIAL_REG. Until the user successfully activates the profile via the email activation link.
  ACTIVE = 'ACTIVE', // After successfully activates the profile via the email activation link, will set to ACTIVE.
  INACTIVE = 'INACTIVE', // When we trigger Temp password for user on FTTA, will change the status as INACTIVE.
  // Until the user successfully reset the original password.
  REVOKED = 'REVOKED', // When user tried invalid login credential's or passcode mismatch on more
  // than 3 times status will change as REVOKED.
  DELETED = 'DELETED', // When user tried to register same email id on ft.com.
  // User will request to delete their profile using incidents. ft.com team will change the status as DELETED
  // and also Users not logged in more than years on Ft.com, will change the status as DELETED.
}

export interface ProfileConfigFromBR {
  profiles: TestProfile[];
}

export interface TestProfile {
  profile: string;
  userRole: string;
  ftToken?: string;
  firmToken?: string;
  name?: string;
}
