import { DC, FA, FP, GK, INST, INT, INV, RIA, SH } from '@utils/app.constants';
import { FirmRole, IFirmProfile } from './profile.interface';
import { GlobalId } from '@types';

export class FirmConfig implements IFirmProfile {
  static readonly PRIMERICA: FirmConfig = new FirmConfig({
    firmRole: FirmRole.PM,
    firmId: '7461384' as GlobalId,
    parentFirmId: '16590073' as GlobalId,
    hasProductPageLinks: true,
    includeInvestedFunds: false,
    hideAccountAccessMenu: false,
  });

  static readonly EDWARD_JONES: FirmConfig = new FirmConfig({
    firmRole: FirmRole.EJ,
    firmId: '7513733' as GlobalId,
    parentFirmId: '16525664' as GlobalId,
    hasProductPageLinks: false,
    includeInvestedFunds: false,
    hideAccountAccessMenu: true,
    hideRoleSelectionLinks: true,
  });

  static readonly ATTRAX: FirmConfig = new FirmConfig({
    firmRole: FirmRole.ATTRAX,
    firmId: 'partner-attrax' as GlobalId,
    parentFirmId: 'partner-attrax' as GlobalId,
    hideSearch: true,
    hideSignIn: true,
    hideRoleSelectionLinks: true,
  });

  static readonly allConfigs: FirmConfig[] = [
    FirmConfig.PRIMERICA,
    FirmConfig.EDWARD_JONES,
    FirmConfig.ATTRAX,
  ];

  firmRole?: FirmRole;
  firmId?: GlobalId;
  parentFirmId?: GlobalId;
  hasProductPageLinks: boolean;
  includeInvestedFunds: boolean;
  hideAccountAccessMenu: boolean;

  constructor(data: IFirmProfile) {
    Object.assign(this, data);
  }

  public static getFirmConfigWithFirmRole(role: FirmRole): IFirmProfile {
    return FirmConfig.allConfigs.find((config) => config.firmRole === role);
  }

  public static getFirmConfigWithParentFirm(parent: GlobalId): IFirmProfile {
    return (
      FirmConfig.allConfigs.find(
        (config) => config.parentFirmId === parent
      ) || {
        parentFirmId: parent,
      }
    );
  }
}
