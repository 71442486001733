import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { WidenAsset, Link } from '@types';
import { Page } from '@bloomreach/spa-sdk';
import { WidenService } from '@services/widen.service';
import { AbstractBaseComponent } from '@shared/abstract-base/abstract-base.component';
import { Logger } from '@utils/logger';
import { AppStateService } from '@services';
import {
  BiographiesDetails,
  BiographyProfile,
} from 'src/app/types/bios-profile.type';
import { BiosProfileService } from '@services/bios-profile.service';

interface ProfileList {
  title?: string;
  type: string;
  profiles?: Array<any>;
  profileWithLink?: Array<any>;
  profilesItems: Array<ProfileItem>;
  biographies?: BiographyProfile[];
  phoneAppointment?: CardData;
  gridColumns?: string;
}

interface ProfileItem {
  fullName: string;
  overview?: string;
  businessGroupTeam?: string;
  profileImage?: WidenAsset;
  profileImageUrl?: string;
  profileLink?: string;
  sharedUrl?: string;
  email: string;
  isShowImage?: boolean;
}

interface BlogsProfileItem {
  name: string;
  displayName: string;
  emailAddress: string;
  employer: string;
  telephoneNumber?: string;
  biography?: string;
  qualifications: string;
  familyName: string;
  firstName: string;
  role: string;
  contentType?: string;
  profileImage?: WidenAsset;
}
interface CardData {
  linkCollection: Array<any>;
  linkCompound: LinkCompound;
  link: LinkCollection;
  signInRequired: boolean;
}
interface LinkCompound {
  url: string;
  displayText: string;
  signInLink?: boolean;
  signInIntroTextLabel?: string;
}
interface LinkCollection {
  linkCollection: Array<any>;
}
/**
 * Logger
 */
const logger = Logger.getLogger('AvatarBlockComponent');

@Component({
  selector: 'ft-avatar-block',
  templateUrl: './avatar-block.component.html',
})
export class AvatarBlockComponent
  extends AbstractBaseComponent
  implements OnInit {
  /**
   * Is Block in a Two Col layout
   */
  @Input()
  isTwoCol: boolean;
  /**
   * Appointment Text
   */
  appointmentText?: string;

  /**
   * is signInRequired
   */
  signInRequired?: boolean;

  /**
   * Appointment URL
   */
  appointmentUrl?: string;
  /**
   * Content to render
   */
  @Input()
  content: ProfileList;

  /**
   * Column numbers
   */
  colNumbers: any;

  /**
   * Bloomreach page model
   */
  @Input()
  page: Page;

  /**
   * Controls if the profile picture/name will contain a hyperlink.
   * if false, then names will not be links.
   */
  @Input()
  navigateToAvatar = true;

  /**
   * Controls if the profile picture is not coming then for insights-page,
   * we don't need to show the circle with Initials.
   * and for other places we need to show circle with Initials if profile picture is not there.
   */
  @Input()
  isRenderedAsArticle = false;

  // DCE-250 Fetch BiosProfile from Elastic
  public doesBiosFetchFromElastic = false;

  public biosProfileItems: BiographiesDetails[] = [];

  /**
   * Constructor
   */
  constructor(
    private appStateService: AppStateService,
    private widenService: WidenService,
    private biosProfileService: BiosProfileService,
    private cdr: ChangeDetectorRef
  ) {
    super();

    this.doesBiosFetchFromElastic = this.appStateService.getFetchBiosFromElasticFlag();
  }

  /**
   * On Init
   */
  ngOnInit(): void {
    if (!this.isTwoCol) {
      // 4 = 100%, 5 = 0.625%, 6 = 50%
      this.colNumbers = { s: 4, m: 5, l: 6 };
    } else {
      this.colNumbers = { s: 'auto', m: 'auto', l: 'auto' };
    }

    // Profiles with Dynamic Links (Document backed)
    const profiles = this.content?.profiles;

    // Profiles with a static XP link
    const profilesWithLink = this.content?.profileWithLink;
    const card = this.content?.phoneAppointment;
    this.content.profilesItems = [];

    // Create profile list to be used in the template
    this.createProfileList(profiles);
    this.createPhoneAppointment(card);
    this.createProfileList(profilesWithLink);

    if (this.doesBiosFetchFromElastic) {
      this.getBiosProfilesData();
    }
  }

  /**
   * Create Phone Appointment
   * @param card object
   */
  private createPhoneAppointment(card: CardData) {
    if (card?.linkCollection?.length) {
      card.linkCompound = card?.linkCollection[0];
    } else if (card?.link?.linkCollection?.length) {
      card.linkCompound = card?.link.linkCollection[0];
    }
    this.signInRequired = card?.linkCompound?.signInLink;
    this.appointmentText = card?.linkCompound?.displayText;
    this.appointmentUrl = card?.linkCompound?.url;
  }

  /**
   * Process an array of profiles from Brxm and display
   * @param profiles array of profiles from BR
   */
  createProfileList(profiles: Array<any>): ProfileList {
    // Loop through
    if (profiles.length) {
      for (const profile of profiles) {
        // Depending on node type ref may be a child node
        const profileRef = profile.profileRef || profile;

        // Get profile document from $ref supporting Core & Blog's profile
        let profileData: ProfileItem | BlogsProfileItem;
        profileData = this.page.getContent(profileRef)?.getData<ProfileItem>();
        // Process widen url for Core or Blog's Profile
        if (profileData?.profileImage) {
          profileData.profileImageUrl = this.widenService.getWidenImageVariantUrl(
            this.widenService.getWidenAssetUrl(
              profileData.profileImage?.widenAsset ||
                profileData.profileImage?.widenDocument
            ),
            'original',
            'webp'
          );
        }
        // Adds break to telephone numbers
        profileData.overview = this.tidyOverview(profileData.overview);

        // If static profile page has been defined
        if (profile.profilePage) {
          profileData.profileLink = this.page
            .getContent(profile.profilePage)
            .getUrl();
        } else if (profileData.sharedUrl) {
          const baseUrl: string = this.appStateService.getSpaBaseUrl();
          profileData.profileLink = baseUrl + profileData.sharedUrl;
        } else {
          // Read profile link from document
          profileData.profileLink = this.page.getContent(profile).getUrl();
        }
        // show the initials / image
        profileData.isShowImage = this.canShowImage(
          profileData.profileImageUrl
        );
        // Add process profile to list.
        this.content.profilesItems?.push(profileData);
      }
    }
    return this.content;
  }

  /**
   * Tidy the overview field to create a break between name
   * and telepgone number
   * @param overview overview string
   */
  tidyOverview(overview: any): string {
    if (!overview?.startsWith('+') && !(overview?.indexOf('<br') > -1)) {
      overview = overview?.replaceAll('+', '<br/>+');
    }
    return overview;
  }

  /**
   * canShowImage if the profile picture is not coming then for insights-page,
   * we don't need to show the circle with Initials.
   * and for other places, we need to show circle with Initials if profile picture is not there.
   */
  public canShowImage(profileImageUrl: string): boolean {
    if (this.isRenderedAsArticle) {
      return profileImageUrl !== 'undefined';
    } else {
      return true;
    }
  }

  /**
   * Fetch Biography profiles from Elatic
   */
  private getBiosProfilesData(): void {
    // Get Biographies from content block
    const bioProfiles = this.content?.biographies;

    if (bioProfiles.length) {
      for (const profile of bioProfiles) {
        if (profile?.biography) {
          const biographyInfo: BiographiesDetails = JSON.parse(
            profile.biography
          );
          const employeeId: string = biographyInfo.employeeId;

          // TODO: Should handle mutiple EmployeeID's and emit all the data once
          this.biosProfileService
            .getProfileFromBiographies(employeeId)
            .subscribe((biosData) => {
              const biosProfileData = biosData;

              if (biosProfileData) {
                biosProfileData.overview = this.setOverviewText(
                  biosProfileData
                );

                // TODO: Should get profile path from the response or BR(Currently works only for the en-us)
                biosProfileData.profilePath = `/profiles-new/${biosProfileData.documentName}`;
                this.biosProfileItems.push(biosProfileData);
                this.cdr.detectChanges();
              }
            });
        }
      }
    }
  }

  /**
   * This method sets the overview text by combining job title's
   * @param biosProfileData from Elastic
   * @returns overview text
   */
  private setOverviewText(biosProfileData: BiographiesDetails): string {
    const jobTitleOne: string = biosProfileData?.jobTitle1;
    const jobTitleTwo: string = biosProfileData?.jobTitle2;

    return (
      (jobTitleOne ?? '') +
      (jobTitleTwo && jobTitleOne ? ', ' : '') +
      (jobTitleTwo ?? '')
    );
  }
}
